<template>
  <div class="popup" v-if="alertPopupContent">
    <div class="popup__bck" @click="closePopup()"></div>
    <div class="popup__block" :class="alertPopupContent.type === 'success' ? 'success' : 'error'" >
      <div class="popup__close"></div>
      <div class="popup__title">{{alertPopupContent.title}}</div>
      <div class="popup__text" v-if="typeof alertPopupContent.text === 'string'">
        {{alertPopupContent.text}}
      </div>
      <div class="popup__text" v-else>
        <p v-for="(item, index) in alertPopupContent.text" :key="index">
          {{index}}: {{item[0]}}
        </p>
      </div>
      <div class="popup__btn">
        <button class="btn grey" @click="closePopup()">close</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  data(){
   return{

   }
  },
  computed:{
    ...mapGetters({
      alertPopupContent:'general/alertPopupContent'
    })
  },
  methods:{
    closePopup(){
      this.toggleAlertPopup({
        status: false,
        content: null
      })
    },
    ...mapMutations({
      toggleAlertPopup:'general/toggleAlertPopup'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
  .popup{
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bck{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0, 0.3);
    }
    &__block{
      position: absolute;
      min-height: 50px;
      max-height: 90vh;
      min-width: 250px;
      max-width: 90vw;
      background-color: $white;
      border-radius: 15px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      overflow: auto;
      &.success{
        box-shadow: 0 0 6px 1px $yellow;
      }
      &.error{
        box-shadow: 0 0 6px 1px $red;
      }
      @media screen and (max-width: 991px){
        max-height: 90vh;
        max-width: 90px;
      }
    }
    &__title{
      font-size: 44px;
      font-family: 'Mont', Arial;
      font-weight: 500;
      color: $grey;
      margin-bottom: 10px;
    }
    &__text{
      margin-bottom: 30px;
    }
  }
</style>
